import { bindable, autoinject, computedFrom } from 'aurelia-framework';
import { SimpleStore } from '../../services/util/SimpleStore';
import { StoreManager } from '../../services/util/StoreManager';
import { subscribe } from '../../util/decorators/subscribe';
import { EventAggregatorWrapper } from '../../util/events/eventAggregatorWrapper';
import { AlertService } from '../../services/util/Alert';
import { Map } from 'ol';
import { MapAdaptor } from '../../services/util/MapAdaptor';
import { IDashboardItem, IMapConfig } from '../../types/Dashboards';
import { getterThrottle } from '../../util/decorators/getterThrottle';
import { DatafiProAPI } from 'services/api/DatafiProAPI';
import { userHasPermission } from '@wsb_dev/datafi-shared/lib/util/users/userHasPermission';

@autoinject
@subscribe({
    events: [{ eventEmitter: 'storeData', event: SimpleStore.change, fn: 'getStoreData' }],
    attached: 'storeAttached',
    detached: 'storeDetached',
})

@subscribe({
    events: [
        { eventEmitter: 'eaw', event: 'edit-item', fn: 'getStoreData' },
    ],
})

export class MapGadget {
    @bindable mapDashboardOptions: IDashboardItem<IMapConfig>;

    @computedFrom('mapDashboardOptions')
    @getterThrottle(100)
    get mapOptions() {
        return { ...this.mapDashboardOptions.config.mapOptions };
    }

    @bindable editEnabled: boolean;
    @bindable mapSelection: Record<string, any>[];
    @bindable map: Map;
    @bindable storeData: SimpleStore[];
    private ma: MapAdaptor;

    constructor(
        private stores: StoreManager,
        private eaw: EventAggregatorWrapper,
        private alerts: AlertService,
        private api: DatafiProAPI,
    ) {
        this.ma = new MapAdaptor;
        this.eaw = eaw;
    }

    async setupMap(map: Map): Promise<void> {
        this.map = map;
        this.ma.map = this.map;

        if (!this.mapDashboardOptions.config.mapOptions) {
            this.updateExtent();
        }
        this.getStoreData();
    }

    unbind() {
        this.eaw.off('edit-item', 'getStoreData');
    }

    async activate(model) {
        this.editEnabled = model.editEnabled;
        this.mapDashboardOptions = model.options as IDashboardItem<IMapConfig>;
    }
    storeAttached() {
        // Called and overriden in subscribe function
    }

    storeDetached() {
        // Called and overriden in subscribe function
    }

    updateExtent() {
        this.mapDashboardOptions.config.mapOptions.view.center = this.map.getView().getCenter();
        this.mapDashboardOptions.config.mapOptions.view.zoom = this.map.getView().getZoom();
    }

    mapChanged() {
        if (this.map) {
            this.setupMap(this.map);
        }
    }

    async getStoreData(editItem?: IDashboardItem<IMapConfig>): Promise<void> {
        if (editItem?.config?.mapOptions) {
            if (editItem.id !== this.mapDashboardOptions.id) {
                return;
            }
            this.mapDashboardOptions = { ...editItem };
        }

        if (this.storeData) {
            this.storeDetached();
        }

        if (this.mapDashboardOptions.config?.storeIds?.length) {
            this.storeData = this.mapDashboardOptions.config.storeIds
                .map((id) => this.stores.getStore(id) as SimpleStore)
                .filter((store) => !!store && (
                store.service?.path !== 'api/v1/programs-users-roles' ||
                userHasPermission(this.api.auth.me, 'api/v1/programs-users-roles', 'find')
                ));

            const promises = this.storeData.map(async (store) => {
                await store.refresh().then(async (result) => {
                    return this.ma.updateLayer({
                        layerId: `store-${result.id}`,
                        update: result.data,
                        updateLayer: true,
                    });
                }).catch((e) => {
                    this.alerts.create({
                        label: `Error fetching DataStore: (${e.message})`,
                        level: 'warning',
                        dismissable: true,
                    });
                });
            });
            await Promise.all(promises);
            this.storeAttached();
        }
    }
}

import { MapAdaptor } from '../../services/util/MapAdaptor';
import { ActionService } from '../../services/actions/ActionService';

import { autoinject } from 'aurelia-dependency-injection';
import { DialogService } from 'aurelia-dialog';
import { bindable } from 'aurelia-templating';
import { EventAggregator } from 'aurelia-event-aggregator';

import { SaveFileActionData } from '@wsb_dev/datafi-shared/lib/types/ActionTaskTypes';
import { ValidatedProjectFile } from '../../types/ProjectFile';

import { ProjectFileEdit } from '../../components/projects/project-files-edit/project-file-edit';
import { SelectionChange } from './events/SelectionChange';

import { FileFolder } from '@wsb_dev/datafi-shared/lib/types/FileFolder';

export type FileGalleryViewType = 'list' | 'gallery';

@autoinject
export class MdcFileGallery {
    @bindable params: Record<string, any>;
    @bindable files: ValidatedProjectFile[];
    @bindable folders: FileFolder[];
    @bindable selectedFolder: FileFolder;

    @bindable active: ValidatedProjectFile;
    @bindable selectedFiles: ValidatedProjectFile[];
    @bindable selectedView: FileGalleryViewType;

    @bindable onFolderSelect;
    @bindable updateProjectsFiles: ()=>void;

    mostRecentSelected: ValidatedProjectFile;

    constructor(
        private actionService: ActionService,
        private ma: MapAdaptor,
        private dialogs: DialogService,
        private ea: EventAggregator,
    ) { }

    getParent(el: HTMLElement, type: string): HTMLElement | undefined {
        while (el) {
            if (el.localName === type.toLowerCase()) {
                return el;
            }
            el = el.parentElement;
        }
    }

    updateSelected(item: ValidatedProjectFile, ev: MouseEvent): void {
        // if clicked element is inside a button, do nothing
        if (this.getParent(ev.target as HTMLElement, 'button')) {
            return;
        }

        // Check if shift key was held
        if (ev.shiftKey && this.mostRecentSelected) {
            let startIndex = this.files.indexOf(this.mostRecentSelected);
            let endIndex = this.files.indexOf(item);

            if (endIndex < startIndex) {
                const temp = startIndex;
                startIndex = endIndex;
                endIndex = temp;
            }

            for (let i = startIndex; i <= endIndex; i++) {
                const itemToSelect = this.files[i];
                itemToSelect.__selected = true;
            }
        } else {
            item.__selected = !item.__selected;
        }

        // track most recent clicked item
        if (ev.type === 'click') {
            this.active = item;
        } else {
            this.active = undefined;
        }

        this.selectedFiles = this.files.filter((file) => !!file.__selected);
        this.mostRecentSelected = item;

        // notify subscribers of selection change
        if (this.ea) {
            this.ea.publish(new SelectionChange(this.selectedFiles));
        }
    }

    getFile(file: ValidatedProjectFile): void {
        const fileParts = file.file_id.split('.');
        const extension = fileParts[fileParts.length - 1];
        const name = file.file_name.indexOf(extension) > -1 ?
            file.file_name :
            `${file.file_name}.${extension}`;
        this.actionService.create({
            id: 'SaveFile',
            target: [{ id: file.file_id }],
            loadingMessage: 'Fetching file, please wait...',
            errorMessage: 'Error: file could not be downloaded',
            showError: true,
            name,
            viewModel: this,
        } as SaveFileActionData);
    }

    async zoom(item: ValidatedProjectFile): Promise<void> {
        const feature = await this.ma.getFeature(item, 'photoFeatures');
        this.ma.zoom(feature);
    }

    edit(index: number): Promise<void> {
        return this.dialogs.open({
            viewModel: ProjectFileEdit,
            model: {
                folders: this.folders,
                files: [...this.files],
                galleryIndex: index,
            },
        }).whenClosed((result) => {
            if (result.wasCancelled) {
                return;
            }
            if (!result.output.files) {
                // deleted
                this.files.splice(result.output.index, 1);
                this.files = [...this.files];
                return;
            }
            this.files[result.output.index] = result.output.files[0];
            this.updateProjectsFiles();
        });
    }
}
